<template>
  <dashboard-wrapper>
    <div class="border-bottom">
      <div class="_flex _between py-4 px-6">
        <div>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb p-0 m-0 bg-transparent tiny">
              <li class="breadcrumb-item"><a href="#">Accueil</a></li>
              <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                Commandes
              </li>
            </ol>
          </nav>
          <h2 class="bold mt-2">Commandes</h2>
        </div>
      </div>
    </div>

    <div class="py-8 px-6">
      <template v-if="getToken">
        <dashboard-noorder />
      </template>
      <template v-else>
        <dashboard-noprofile />
      </template>
    </div>
  </dashboard-wrapper>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
export default {
  computed: {
    ...mapGetters([
        'profile',
        "getToken",
        "getUserData",
    ])
  }
};
</script>
